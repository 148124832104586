<template>
    <div class="gallery">
        <div class="gallery-track block">
            <div class="slide" v-for="lamp in lamps" :key="lamp">
                <img :src="lamp" alt="" class="lamp">
            </div>
        </div>
        <div class="gallery-track-2">
            <div class="slide" v-for="lamp in lampstwo" :key="lamp">
                <img :src="lamp" alt="" class="lamp">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            lamps: [ 
                require('../assets/image1.png'),
                require('../assets/image2.png'),
                require('../assets/image3.png'),
                require('../assets/image4.png'),
                require('../assets/image5.png'),
                require('../assets/image6.png'),
                require('../assets/image7.png'),
                require('../assets/image8.png'),
            ],
            lampstwo: [ 
                require('../assets/image9.png'),
                require('../assets/image10.png'),
                require('../assets/image11.png'),
                require('../assets/image12.png'),
                require('../assets/image13.png'),
                require('../assets/image14.png'),
                require('../assets/image15.png'),
                require('../assets/image16.png'),
            ]
        }
    }
}
</script>

<style scoped>
body{
	align-items: center;
	display: flex;
	justify-content: center;
    
}
.gallery {
	background: transparent;
	margin: auto;
	overflow:hidden;
	position: relative;
	width: 640px;
}
.gallery::before, .gallery::after {
    content: "";
    height: 100px;
    position: absolute;
    width: 200px;
    z-index: 2;
}
.gallery::after {
    right: 0;
    top: 0;
    -webkit-transform: rotateZ(180deg);
    transform: rotateZ(180deg);
}

.gallery::before {
    left: 0;
    top: 0;
}
.gallery-track {
    animation: scroll 60s linear infinite;
    display: flex;
    width: calc(250px * 14);
}
.gallery-track-2 {
    animation: scroll-2 60s linear infinite;
    display: flex;
    width: calc(250px * 14);
}
.slide {
    height: 240px;
    width: 240px;
    margin: 0 12px;
}
.lamp{
    border-radius: 32px;
}
/* tablet */
@media screen and (max-width: 1023px) {
    .gallery {
        width: 100%;
    }
}
/* mobile */
@media screen and (max-width: 768px){
    .slide{
        height: 160px;
        width: 160px;
    }
}
</style>