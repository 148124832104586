import { initializeApp } from "firebase/app"
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: process.env.VUE_APP_API_KEY,
    authDomain: "persona-lamps.firebaseapp.com",
    projectId: "persona-lamps",
    storageBucket: "persona-lamps.appspot.com",
    messagingSenderId: "951888403254",
    appId: "1:951888403254:web:aac133a6d95de14b3d3197",
    measurementId: "G-R5488YECHX"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export {db}