<template>
    <div class="webtoon" v-show="holder" :class="{'is-active': !english && !chinese}">
        <div class="hero" :class="{'is-active': english || chinese}">
            <div class="hero-head">
                <Navbar />
            </div>
            <div class="hero-body">
                <div class="select-language" v-show="english == false && chinese == false">
                    <p class="is-size-3 block has-text-white has-text-centered">Select your language</p>
                    <div class="language ">
                        <div class="box has-text-white" @click="selectLanguage(1)">
                            <p class="is-size-5">English</p>
                        </div>
                        <div class="box has-text-white" @click="selectLanguage(2)">
                            <p class="is-size-5">Chinese</p>
                        </div>
                    </div>
                </div>
                <img class="comic-img" v-show="english" src="../assets/English-A.png" alt="">
                <img class="comic-img" v-show="english" src="../assets/English-B.png" alt="">
                <img class="comic-img" v-show="english" src="../assets/English-C.png" alt="">
                <img class="comic-img" v-show="chinese" src="../assets/Chinese-A.png" alt="">
                <img class="comic-img" v-show="chinese" src="../assets/Chinese-B.png" alt="">
                <img class="comic-img" v-show="chinese" src="../assets/Chinese-C.png" alt="">

                <p class="has-text-white has-text-centered block" v-show="english || chinese">Download files</p>
                <div class="container" v-show="english">
                    <a :href="A" download>Part A</a>
                    <a :href="B" download>Part B</a>
                    <a :href="C" download>Part C</a>
                </div>
                <div class="container" v-show="chinese">
                    <a :href="D" download>Part A</a>
                    <a :href="E" download>Part B</a>
                    <a :href="F" download>Part C</a>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import { ref } from '@vue/reactivity'
import { holder } from '@/functions/getNFTs'
import A from '@/assets/English-A.png'
import B from '@/assets/English-B.png'
import C from '@/assets/English-C.png'
import D from '@/assets/Chinese-A.png'
import E from '@/assets/Chinese-B.png'
import F from '@/assets/Chinese-C.png'
export default {
    components: {
        Navbar,
        Footer
    },
    setup(){
        const english = ref(false);
        const chinese = ref(false);
        const selectLanguage = (index) => {
            switch(index) {
                case 1: 
                    english.value = true;
                    chinese.value = false;
                    break;
                case 2: 
                    chinese.value = true;
                    english.value = false;
                    break
            }
        }
        return { english, chinese, selectLanguage, holder, A, B, C, D, E, F}
    }
}
</script>

<style scoped>
.webtoon{
    font-family: 'Rubik', sans-serif;
    position: relative;
    width: 100%;
    background: black;
}
.webtoon.is-active{
    background: url('../assets/bg_stars.png');
    background-position: center;
    background-size: cover;
}
.hero{
    height: 100vh;
}
.select-language, .hero-body{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.hero.is-active{
    height: 100%;
}
.language{
    display: flex;
}
.box{
    height: 180px;
    width: 200px;
    background: transparent;
    border: 1px solid white;
    border-radius: 12px;
    box-shadow: 0px 0px 10px 2px #31D673;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 20px;
    transition: 400ms;
}
.box:hover{
    cursor: pointer;
    transform: scale(1.03);
}
img{
    max-width: 150px;
}
.comic-img{
    min-width: 700px;
}
a{
    margin: 0 12px;
    color: rgba(255, 255, 255, 0.521);
    transition: 300ms;
}
a:hover{
    color: white;
    text-shadow: 0px 0px 12px #31D673;
}

/* mobile */
@media screen and (max-width: 768px){
    .box{
        height: 200px;
        width: 150px;
    }
    img{
        max-width: 50px;
    }
    .comic-img{
        min-width: 300px;
    }
}
</style>