import { createStore } from 'vuex'

export default createStore({
  state: {
    showNav: false,
    star: false,
    solved: false,
    showNotification: false
  },
  mutations: {
    SOLVE_PUZZLE(state){
        state.solved = true
    }
  },
  actions: {
  },
  modules: {
  }
})
