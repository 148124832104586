<template>
    <div class="roadmap section">
        <div class="is-size-2 is-uppercase has-text-white has-text-weight-semibold has-text-centered-touch block">roadmap</div>
        <Menu class="menu" />
    </div>
</template>

<script>
import Menu from '@/components/Menu.vue'
export default {
    components: {
        Menu
    }
}
</script>

<style scoped>
.roadmap{
    position: relative;
    width: 100%;
    height: 100vh;
    background: url('../assets/roadmap_bg.png');
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.menu{
    margin: 0 auto;
}
.is-size-2{
    margin-top: 24px;
}
/* mobile */
@media screen and (max-width: 768px){
    .roadmap{
        height: 140vh;
    }
}
</style>