import { currAccount } from './getWallet'
import { getNFTs } from './getNFTs';

const connectWallet = () => {
    const { ethereum } = window;
    if(!ethereum){
      console.log("Get Metamask!")
    }else{
      ethereum.request({method: 'eth_requestAccounts'})
        .then(accounts => {
          currAccount.value = accounts[0]
          getNFTs()
        })
        .catch(err => console.log(err))
    }
  }

export default connectWallet