<template>
  <div class="home" :class="{'is-active': $store.state.showNav}">
    <Hero />
    <About id="about"/>
    <Rarity id="rarity" />
    <RaritySniper />
    <Roadmap id="roadmap" />
    <Team id="team" />
    <Footer />
  </div>
</template>

<script>
import Hero from '@/components/Hero.vue'
import About from '@/components/About.vue'
import Rarity from '@/components/Rarity.vue'
import RaritySniper from '@/components/RaritySniper.vue'
import Roadmap from '@/components/Roadmap.vue'
import Team from '@/components/Team.vue'
import Footer from '@/components/Footer.vue'
import getCollection from '../functions/getCollection'
export default {
  name: 'Home',
  components: {
    Hero,
    About,
    Rarity,
    RaritySniper,
    Roadmap,
    Team,
    Footer
  }
}
</script>

<style scoped>
/* tablet */
@media screen and (max-width: 1023px) {
  /* disable scroll when navbar active */
  .is-active{
    margin: 0; 
    height: 100vh; 
    overflow: hidden;
  }
}
</style>
