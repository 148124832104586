import { collection, getDocs, query } from '@firebase/firestore'
import {ref} from 'vue'
import { db } from '../services/firebase'


const getCollection = (col) => {
    const addresses = ref([]);
    const documents = ref([]);
    const error = ref(null);
    const load = async () => {
        try {
            const q = query(collection(db, col));
            const res = await getDocs(q)
            documents.value = res.docs.map(doc => {
                return {...doc.data(), id: doc.id}
            })
            documents.value.forEach(doc => {
                addresses.value.push(doc.address)
            })
            console.log(addresses.value)
            
        } catch (err) {
            console.log(err.message)
        }
    }
    return {addresses, error, load}
}

export default getCollection