<template>
    <div class="roadmap-component">
        <div class="years-container" :class="{'year1Active': year1, 'year2Active': year2}">
            <div class="year" :class="{'glow-2': year1}" @click="activateYear(1)">
                <p>2022</p>
            </div>
            <div class="year" :class="{'glow-2': year2}" @click="activateYear(2)">
                <p>2023</p>
            </div>
        </div>
        <div v-show="year1" class="quarter-container" :class="{'quarter1Active': quarter1, 'quarter2Active': quarter2, 'quarter3Active': quarter3, 'quarter4Active': quarter4}">
            <div class="quarter" :class="{'glow': quarter1}" @click="activateQuarter(1)">
                <p>Q1</p>
            </div>
            <div class="quarter" :class="{'glow': quarter2}" @click="activateQuarter(2)">
                <p>Q2</p>
            </div>
            <div class="quarter" :class="{'glow': quarter3}" @click="activateQuarter(3)">
                <p>Q4</p>
            </div>
        </div>
        <div v-show="year2" class="quarter-container" :class="{'quarterActive': quarter}">
            <div class="quarter" :class="{'glow': quarter}">
                <p>Q3</p>
            </div>
        </div>
        <div class="item-list" v-show="year1">
            <div v-for="item in list1" :key="item" v-show="quarter1">
                <ul>
                    <li class="list-item">∙ {{ item }}</li>
                </ul>
            </div>
            <div v-for="item in list2" :key="item" v-show="quarter2">
                <ul>
                    <li class="list-item">∙ {{ item }}</li>
                </ul>
            </div>
            <div v-for="item in list3" :key="item" v-show="quarter3">
                <ul>
                    <li class="list-item">∙ {{ item }}</li>
                </ul>
            </div>
            <div v-for="item in list4" :key="item" v-show="quarter4">
                <ul>
                    <li class="list-item">∙ {{ item }}</li>
                </ul>
            </div>
        </div>
        <div class="item-list" v-show="year2">
            <div v-for="item in list2024" :key="item" v-show="quarter">
                <ul>
                    <li class="list-item">∙ {{ item }}</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>

import { ref } from '@vue/reactivity'
  export default {
    setup(){
        const year1 = ref(true)
        const year2 = ref(false)
        const quarter = ref(false)
        const quarter1 = ref(true)
        const quarter2 = ref(false)
        const quarter3 = ref(false)
        const quarter4 = ref(false)
        const list1 = ['Animated Chibi Lamp Airdrop to all Lamp Holders', '2 Lamps Giveaway to random people', 'Evolution of Lamps to Character - Tease', '5 Mythic Lamps Airdrop to random Lamp Holders', 'Rarity Tools', 'Rarity Sniper', 'Character pose Poll', 'AMAs', 'Holder-Only Web Comic Teaser and Prologue']
        const list2 = ['Collab with Aiko Virtual', 'Web Comic Chapter 1', 'Web Comic Chapter 2', 'Web Comic Chapter 3', 'Mythic Characters Transformations Released'] 
        const list3 = ['Trailer for Rare Characters', 'Chapter 4', 'Chapter 5', 'Rare Characters Transformations Released']
        const list2024 = ['Common Characters Transformations Released']

        const activateYear = (index) => {
            switch(index){
                case 1:
                    quarter.value = true;
                    year1.value = true;
                    year2.value = false;
                    break;
                case 2:
                    quarter.value = true;
                    year1.value = false;
                    year2.value = true;
                    break
            }
        }
        const activateQuarter = (index) => {
            switch(index){
                case 1:
                    quarter.value = false;
                    quarter1.value = true;
                    quarter2.value = false
                    quarter3.value = false
                    quarter4.value = false
                    break;
                case 2:
                    quarter.value = false;
                    quarter2.value = true
                    quarter1.value = false
                    quarter3.value = false
                    quarter4.value = false
                    break;
                case 3:
                    quarter.value = false;
                    quarter3.value = true;
                    quarter1.value = false
                    quarter2.value = false
                    quarter4.value = false
                    break;
            }
        }
        return {year1, year2, quarter, quarter1, quarter2, quarter3, activateQuarter, activateYear, list1, list2, list3, list2024}
    }
  }
</script>

<style scoped>
.roadmap-component{
    font-family: 'Montserrat', sans-serif;
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.years-container{
    position: absolute;
    z-index: 99;
    left: 60px;
    width: 180px;
    height: 180px; 
    background: transparent;
    border: 3px solid white;
    box-shadow: 5px 0 5px -2px rgba(49, 214, 115, 1);
    border-width: 0px 4px 0px 0px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 500ms;
}
.year{
    position: absolute;
    color: rgba(255,255,255,0.5) !important;
    transition: 500ms;
}
.quarter-container{
    position: absolute;
    z-index: 98;
    width: 350px;
    height: 350px; 
    background: transparent;
    border: 3px solid white;
    box-shadow: 5px 0 5px -2px rgba(49, 214, 115, 1);
    border-width: 0px 4px 0px 0px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 500ms;
}
.quarter1Active{
    transform: rotate(0deg);
}
.quarter2Active{
    transform: rotate(-30deg);
}
.quarter-container.quarter2Active{
    transform: rotate(-30deg);
    border-width: 0px 4px 4px 0px;
}
.quarter2Active p {
    transform: rotate(30deg);
}
.quarter3Active{
    transform: rotate(-60deg);
}
.quarter-container.quarter3Active{
    transform: rotate(-60deg);
    border-width: 0px 4px 4px 0px;
}
.quarter3Active p {
    transform: rotate(60deg);
}
.quarter4Active{
    transform: rotate(-90deg);
}
.quarter-container.quarter4Active{
    transform: rotate(-90deg);
    border-width: 0px 0px 4px 0px;
}
.quarter4Active p {
    transform: rotate(90deg);
}
.quarter-container .quarter:nth-child(1) {
    transform: translateX(110px);
}
.quarter-container .quarter:nth-child(2) {
    transform: translateX(100px) translateY(55px);
}
.quarter-container .quarter:nth-child(3) {
    transform: translateX(60px) translateY(100px);
}
.quarter-container .quarter:nth-child(4) {
    transform: translateY(110px);
}
.quarter{
    cursor: pointer;
    position: absolute;
    color: rgba(255,255,255,0.5);
    transition: 500ms;
}
.year{
    cursor: pointer;
}
.glow{
    text-shadow: 0px 0px 12px #31D673;
    font-size: 26px;
    color: white;
    font-weight: 600;
}
.glow-2{
    text-shadow: 0px 0px 12px #31D673;
    font-size: 30px;
    color: white !important;
    font-weight: 600;
}
.item-list{
    position: absolute;
    z-index: 97;
    left: 400px;
    height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
}
.list-item{
    margin-bottom: 8px;
    transition: 400ms;
    font-size: 18px;
    color: white;
    text-shadow: 0px 0px 12px #31D673;
}
.years-container .year:nth-child(1) {
    transform: translateX(25px);
}
.years-container .year:nth-child(2) {
    transform: translateX(-15px) translateY(40px);
    
}
.years-container.year2Active{
    border-width: 0px 4px 4px 0px;
}
.year1Active{
    transform: rotate(0deg);
}
.year2Active{
    transform: rotate(-30deg);
}
.year2Active .year:nth-child(1) {
    transform: rotate(30deg) translateX(-15px) translateY(-40px);
}
.year2Active .year:nth-child(2) {
    transform: rotate(30deg) translateX(25px);
}
.quarter2Active, .quarter3Active, .quarter4Active{
    transform: rotate(0deg);
}
/* tablet */
@media screen and (max-width: 1023px) {
    .years-container{
        left: 30px;
        width: 150px;
        height: 150px; 
    }
    .quarter-container{
        position: absolute;
        z-index: 98;
        width: 270px;
        height: 270px; 
    }
    .glow{
        font-size: 20px;
    }
    .glow-2{
        font-size: 24px;
    }
    .item-list{
        left: 300px;
        height: 600px;
    }
    .list-item{
        font-size: 16px;
    }
    .quarter-container .quarter:nth-child(1) {
        transform: translateX(90px);
    }
    .quarter-container .quarter:nth-child(2) {
        transform: translateX(80px) translateY(45px);
    }
    .quarter-container .quarter:nth-child(3) {
        transform: translateX(50px) translateY(85px);
    }
    .quarter-container .quarter:nth-child(4) {
        transform: translateY(95px);
    }
}
/* mobile */
@media screen and (max-width: 768px){
    .roadmap-component{
        font-family: 'Montserrat', sans-serif;
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
    }
    .years-container{
        position: relative;
        left: 0;
        top: 45px;
        width: 100%;
        height: fit-content;
        border-radius: 0;
        border-width: 0;
        box-shadow: none;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .quarter-container{
        position: absolute;
        border: none;
        box-shadow: none;
        border-radius: 0;
        left: 0;
        top: 120px;
        width: 100%;
        height: fit-content; 
        justify-content: center;
    }
    .item-list{
        position: absolute;
        left: 50%;
        margin-left: -150px;
        width: 300px;
        top: 180px;
        justify-content: flex-start;
    }
    .list-item{
        font-size: 16px;
        color: white;
        text-shadow: 0px 0px 12px #31D673;
        text-align: center;
        margin-bottom: 12px;
    }
    .list-item:hover{
        font-size:16px;
    }
    .years-container .year:nth-child(1) {
        transform: translateX(-100px);
    }
    .years-container .year:nth-child(2) {
        transform: translateX(0) translateY(0px);
    }
    .year2Active{
        transform: rotate(0deg);
        border: none;
    }
    .year1Active{
        transform: translateX(100px);
    }
    .quarter-container .quarter:nth-child(4) {
        transform: translateX(180px);
    }
    .quarter-container .quarter:nth-child(3) {
        transform: translateX(120px) translateY(0px);
    }
    .quarter-container .quarter:nth-child(2) {
        transform: translateX(60px) translateY(0px);
    }
    .quarter-container .quarter:nth-child(1) {
        transform: translateY(0px);
    }
    .quarter2Active p, .quarter3Active p, .quarter4Active p {
        transform: rotate(0deg);
    }
    .quarter-container.quarter1Active{
        transform: translateX(0px);
    }
    .quarter-container.quarter2Active{
        transform: translateX(-60px);
    }
    .quarter-container.quarter3Active{
        transform: translateX(-120px);
    }
    .quarter-container.quarter4Active{
        transform: translateX(-180px);
    }
    .glow{
        font-size: 28px;
    }
    .glow-2{
        font-size: 32px;
    }
}
</style>