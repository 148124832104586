<template>
    <div class="about section">
        <div class="is-size-2 is-uppercase has-text-weight-semibold has-text-centered-touch top">About</div>
        <div class="columns top">
            <div class="column">
                <p class="has-text-white is-size-5 block">Persona Lamps is a collection of 4,444 LAMP NFTs with over 100 variations and countless combination of traits that each represents the light generated throughout the NFT universe.</p>
                <p class="has-text-white is-size-5 block">Unlike characters taking the form of animals or other breed of humans, Persona Lamps portray a set of identities with each lamp representing a character. They do not show any emotions or facial expressions but emit a strong vibe and aura towards the audience.</p>
                <p class="has-text-white is-size-5 block">Each Persona Lamp wears a type of clothing that represents their current or past occupations, with the traits hovering around them as their occupation or interests.</p>
                <p class="has-text-white is-size-5 block">Your Persona Lamp will also grant you access to future events and expansions.</p>
            </div>
            <div class="column is-half-desktop">
                <Gallery class="gallery"/>
            </div>
        </div>
    </div>
</template>

<script>
import Gallery from '@/components/Gallery.vue'
export default {
    components: {
        Gallery
    }
}
</script>

<style scoped>
.about{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: fit-content;
    background: url('../assets/bg_stars.png');
    background-position: center;
    background-size: cover;
    font-family: 'Rubik', sans-serif;
}
.is-size-2{
    color: #31D673;
    margin: 42px 0 24px 0;
}
.container{
    display: flex;
}
.top{
    position: relative;
    z-index: 98;
}
/* tablet */
@media screen and (max-width: 1023px) {
    .about{
        height: fit-content;
    }
    .columns{
        flex-direction: column-reverse;
    }
}
/* mobile */
@media screen and (max-width: 768px){
    .about{
        height: fit-content;
    }
    .columns{
        display: flex;
        flex-direction: column-reverse;
    }
}
</style>