<template>
    <svg width="405" height="143" viewBox="0 0 405 143" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M37.0001 54.5C29 54.5 22.5 50.5 19.0001 41V104C19.0001 104 25.7116 103.784 28 105.5C35.3333 111 31.5 120.5 26.5 123H13.5C4.00005 120.5 1.33339 111.167 1.50005 106.5V16C1.50005 6 10.1667 2.5 14.5001 2C19.6667 1.83334 32.2001 1.6 41.0001 2C49.8001 2.4 53.6667 10.5 54.5001 14.5V41C54.5001 48.5 49.5 54.5 37.0001 54.5ZM37 19H19V37H37V19Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M100 37C107.5 32.5 116 25.5 116 20V16C116 8.4 108.5 2 102.5 2H77C72.3334 2.16667 63 5 63 15V40.5C62.8334 45.1667 65.7 54.5 78.5 54.5H106.5C109 54.5 114 53.1 114 47.5V42.5C113.833 40.8333 112.2 37.4 107 37H100ZM98 19.5H80.5L80.5 36.8131H98V19.5Z" fill="white"/>
        <path d="M124.5 10C124.5 5 127.5 1.5 131.5 1.5H135C137 1.5 141.5 5 141.5 10C141.5 16.0133 142 14 142 14C143.5 10.5 146.4 1.5 152 1.5H156.5C158.667 1.66667 163.5 3.38573 163.5 9C163.5 11 163.239 12 163 13C162.134 16.6285 160.5 19 156.5 19.5H142V47.5C142 49.8333 140.7 54.5 135.5 54.5H131C128.833 54.3333 124.5 52.7 124.5 47.5V10Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M180.761 34.7926C177.394 32.9291 174.049 31.0783 171.5 27C169.87 24.3919 169.929 21.8675 169.982 19.63C169.991 19.2445 170 18.8675 170 18.5C170 10.768 174.8 1.5 184 1.5H211C216 1.5 220.5 4.9 220.5 8.5V12.5C220.5 15 218 19 213.5 19H206C208.214 19.7381 212.566 21.2091 215.5 24C218.281 26.6451 220.5 30.2142 220.5 32.5V41.5C220.5 46.0765 215.7 54.5 206.5 54.5C203.501 54.5 199.375 54.5133 195.158 54.5269C186.441 54.5549 177.337 54.5843 177 54.5C175 54 170.734 50.9387 170 47C169.75 45.6563 169.71 44.8357 170 43.5C170.854 39.5662 172.8 37 178 37H184.5C183.277 36.1849 182.018 35.4879 180.761 34.7926ZM204.304 19H187.237L187.237 36.6685H204.304V19Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M272.761 102.513C269.394 100.581 266.049 98.6624 263.5 94.4346C261.87 91.7309 261.929 89.1139 261.982 86.7945C261.991 86.3948 262 86.004 262 85.623C262 77.6077 266.8 68 276 68H303C308 68 312.5 71.5246 312.5 75.2565V79.4031C312.5 81.9948 310 86.1414 305.5 86.1414H298C300.214 86.9065 304.566 88.4315 307.5 91.3246C310.281 94.0667 312.5 97.7666 312.5 100.136V109.466C312.5 114.21 307.7 122.942 298.5 122.942C295.501 122.942 291.375 122.956 287.158 122.97C278.441 122.999 269.337 123.03 269 122.942C267 122.424 262.734 119.251 262 115.168C261.75 113.775 261.71 112.924 262 111.539C262.854 107.461 264.8 104.801 270 104.801H276.5C275.277 103.956 274.018 103.233 272.761 102.513ZM296.304 86.1414H279.237L279.237 104.457H296.304V86.1414Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M196 81C196.167 76.3333 199.5 67 211.5 67H236.5C241.5 67 252 72 252 81V109C251.833 113.833 246.5 123.5 238.5 123.5H230C226 123.5 220.5 122 214 109V134C214.167 136.667 212.9 142 206.5 142H203C200.667 142 196 140.4 196 134V81ZM214 104.5V86H233V104.5H214Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M244 1C235.163 1 228 8.16344 228 17V39C228 47.8366 235.163 55 244 55H265C273.837 55 281 47.8366 281 39V17C281 8.16344 273.837 1 265 1H244ZM264 19H246V37H264V19Z" fill="white"/>
        <path d="M289.5 12.5V47C289.833 49.5 291.7 54.5 296.5 54.5H300C302.333 54.5 307.1 53 307.5 47C307.9 41 307.667 26.1667 307.5 19.5H323.5V47C323.5 49.5 324.9 54.5 330.5 54.5H334C336.5 54.5 341.5 53 341.5 47V17.5C341.5 12 337.4 1 325 1C325 1 326.038 1.00001 323.5 1.00001C318.063 0.934048 307.845 6.71044 307.511 12.1372C307.509 12.2615 307.505 12.3826 307.5 12.5C307.5 12.3785 307.504 12.2576 307.511 12.1372C307.584 8.20411 306.204 1 300 1H296.5C294.167 1.16667 289.5 3.7 289.5 12.5Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M103 115.29V79.8229C103 70.7762 107.667 68.1719 110 68.0006H113.5C119.704 68.0006 121.084 75.4066 121.011 79.4499C121.004 79.5736 121 79.698 121 79.8229C121.005 79.7021 121.009 79.5777 121.011 79.4499C121.345 73.8711 131.563 67.9328 137 68.0006C137.843 68.0006 138.291 68.0006 138.514 68.0006C146.064 68.0059 150.534 72.2048 152.875 76.7633C155.501 72.1202 163.436 67.9436 168 68.0006C168.843 68.0006 169.291 68.0006 169.514 68.0006C181.903 68.0093 186 79.311 186 84.963V115.29C186 121.458 181 123 178.5 123H175C169.4 123 168 117.86 168 115.29V87.0191H155V115.29C155 121.458 150 123 147.5 123H144C138.4 123 137 117.86 137 115.29V87.0191H121C121.167 93.8726 121.4 109.122 121 115.29C120.6 121.458 115.833 123 113.5 123H110C105.2 123 103.333 117.86 103 115.29ZM169.5 68.0006C169.5 68.0006 169.963 68.0006 169.514 68.0006C169.51 68.0006 169.505 68.0006 169.5 68.0006ZM138.5 68.0006C138.5 68.0006 138.963 68.0006 138.514 68.0006C138.51 68.0006 138.505 68.0006 138.5 68.0006Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M55.354 86.4956H48.9115C46.2684 86.4956 40.9823 84.9381 40.9823 78.708V75.3009C40.9823 72.8673 42.5681 68 48.9115 68H79.6372C85.0885 68 95 70.9204 95 81.6283V108.398C94.8348 113.265 91.0354 123 77.1593 123H52.8761C48.9115 123 39 118.327 39 107.425V104.504C39 97.6903 52.1823 88.0531 55.354 86.4956ZM76.1681 86.4956H57.8319V104.504H76.1681V86.4956Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M365.478 19.823H359.381C356.879 19.823 351.876 18.3221 351.876 12.3186V9.0354C351.876 6.69026 353.377 2 359.381 2H388.46C393.619 2.00002 403 4.81419 403 15.1327V40.9292C402.844 45.6195 399.248 55 386.115 55H363.133C359.381 55 350 50.4974 350 39.9912V37.177C350 30.6106 362.476 21.3239 365.478 19.823ZM385.177 19.823H367.823V37.177H385.177V19.823Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M371.226 105C371.079 105.477 371 105.98 371 106.5C371 109.538 373.686 112 377 112C380.314 112 383 109.538 383 106.5C383 105.98 382.921 105.477 382.774 105H371.226Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M375.085 80H378.915C378.97 79.8436 379 79.6753 379 79.5V71.5C379 70.6716 378.328 70 377.5 70H376.5C375.672 70 375 70.6716 375 71.5V79.5C375 79.6753 375.03 79.8436 375.085 80ZM382.004 86H371.996L356.192 101.793C355.562 102.422 356.008 103.5 356.899 103.5H397.101C397.992 103.5 398.438 102.422 397.808 101.793L382.004 86ZM381 81H373V85H381V81Z" fill="white"/>
        <rect x="375" y="128" width="10" height="4" rx="2" transform="rotate(-90 375 128)" fill="white"/>
        <rect x="360.05" y="120.121" width="10" height="4" rx="2" transform="rotate(-45 360.05 120.121)" fill="white"/>
        <rect x="386.879" y="113.05" width="10" height="4" rx="2" transform="rotate(45 386.879 113.05)" fill="white"/>
    </svg>
</template>