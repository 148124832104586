import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Webtoon from '../views/Webtoon.vue'
import { holder } from '../functions/getNFTs'
import connectWallet from '../functions/connectWallet'
import { currAccount } from '@/functions/getWallet'

const exceptions = [
  "0x333a4e50fa0db28680add0ec81cc59795c2d92d8",
  "0x585b4677bf6fecf952fb2c96a71bbed16d24b635",
  "0xfd45cd3aca3c21e1600f07ea60caa96b2d6d3d05",
  "0xe700c73cdd82d1ed8333d20b87efc1bad653e18c"
]

//auth guard 
const requireAuth = (from, to, next) => {
  connectWallet()
  if(holder.value || exceptions.includes(currAccount.value)){
    next()
  }else{
    next('/')
  }
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/webcomic',
    name: 'Webcomic',
    component: Webtoon,
    beforeEnter: requireAuth,
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
