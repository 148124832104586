import personaABI from "./personaABI";
import { currAccount } from './getWallet'
import Web3 from "web3";
import { ref } from "vue";

const holder = ref(false);

const exceptions = [
    "0x333a4e50fa0db28680add0ec81cc59795c2d92d8",
    "0x585b4677bf6fecf952fb2c96a71bbed16d24b635",
    "0xfd45cd3aca3c21e1600f07ea60caa96b2d6d3d05",
    "0xe700c73cdd82d1ed8333d20b87efc1bad653e18c"
]

const getNFTs = async () => {
    const web3 = new Web3(window.ethereum);
    const contract = new web3.eth.Contract(personaABI, "0xA3B041eE6b56BCcBc54A3048417d82Fe67736F62");
    contract.defaultAccount = currAccount.value
    const personaLampsBalance = await contract.methods.balanceOf(currAccount.value).call()

    if( exceptions.includes(currAccount.value) || personaLampsBalance > 0 ){
        holder.value = true
    }
    
}

export { getNFTs, holder }