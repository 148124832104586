<template>
    <div class="rarity section">
        <svg class="triangle-1 is-hidden-touch"  viewBox="0 0 1361 775" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 0L1361 775H0V0Z" fill="#3D308B" fill-opacity="0.46"/>
        </svg>
        <svg class="triangle-2 is-hidden-touch"  viewBox="0 0 1512 470" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1512 0L0 470H1512V0Z" fill="#411A96" fill-opacity="0.56"/>
        </svg>
        <svg class="triangle-1 is-hidden-desktop" viewBox="0 0 834 1900" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 0L834 1900H0V0Z" fill="#3D308B" fill-opacity="0.46"/>
        </svg>
        <svg class="triangle-2 is-hidden-desktop" viewBox="0 0 834 1595" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M834 0L0 1595H834V0Z" fill="#411A96" fill-opacity="0.56"/>
        </svg>
        <div class="content">
            <div class="is-size-2 is-uppercase has-text-white has-text-weight-semibold has-text-centered-touch block">rarity</div>
            <div class="columns">
                <div class="column is-one-third-desktop is-one-touch" v-for="rarity in rarities" :key="rarity">
                    <div class="image-container">
                        <img :src="rarity.image" alt="">
                    </div>
                    <p class="is-size-4 is-uppercase has-text-white has-text-weight-semibold">{{ rarity.name }}</p>
                    <div class="percentage-container is-flex block">
                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14 18.375C11.9114 18.375 9.90838 19.1125 8.43153 20.4253C6.95469 21.738 6.125 23.5185 6.125 25.375H21.875C21.875 23.5185 21.0453 21.738 19.5685 20.4253C18.0916 19.1125 16.0886 18.375 14 18.375Z" fill="#C4C4F4" stroke="#C4C4F4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M20.125 8.75C20.125 10.3745 19.4797 11.9324 18.331 13.081C17.1824 14.2297 15.6245 14.875 14 14.875C12.3755 14.875 10.8176 14.2297 9.66897 13.081C8.52031 11.9324 7.875 10.3745 7.875 8.75C7.875 7.12555 8.52031 5.56763 9.66897 4.41897C10.8176 3.27031 12.3755 2.625 14 2.625C15.6245 2.625 17.1824 3.27031 18.331 4.41897C19.4797 5.56763 20.125 7.12555 20.125 8.75ZM14.7656 11.8125C14.7656 12.0156 14.685 12.2103 14.5414 12.3539C14.3978 12.4975 14.2031 12.5781 14 12.5781C13.7969 12.5781 13.6022 12.4975 13.4586 12.3539C13.315 12.2103 13.2344 12.0156 13.2344 11.8125C13.2344 11.6094 13.315 11.4147 13.4586 11.2711C13.6022 11.1275 13.7969 11.0469 14 11.0469C14.2031 11.0469 14.3978 11.1275 14.5414 11.2711C14.685 11.4147 14.7656 11.6094 14.7656 11.8125ZM14 4.92188C13.7969 4.92188 13.6022 5.00254 13.4586 5.14612C13.315 5.2897 13.2344 5.48444 13.2344 5.6875V8.75C13.2344 8.95306 13.315 9.1478 13.4586 9.29138C13.6022 9.43496 13.7969 9.51562 14 9.51562C14.2031 9.51562 14.3978 9.43496 14.5414 9.29138C14.685 9.1478 14.7656 8.95306 14.7656 8.75V5.6875C14.7656 5.48444 14.685 5.2897 14.5414 5.14612C14.3978 5.00254 14.2031 4.92188 14 4.92188Z" fill="#C4C4F4"/>
                        </svg>
                        <p class="percentage has-text-white is-size-4">{{ rarity.percentage }}%</p>
                    </div>
                    <div class="container">
                        <p class="description">{{ rarity.description }}</p>
                        <p class="traits-title has-text-weight-semibold has-text-white">Traits</p>
                        <div class="traits-container">
                            <div v-for="trait in rarity.traits" :key="trait">
                                <p class="trait is-capitalized has-text-weight-bold">{{ trait }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            rarities: [
                    {name: 'rare', percentage: 33.8, description: 'Lamps who mutate due to overwhelming emotions and thoughts in their host by consuming the mind of other common lamps evolves to Rare Lamps. They have partial control of their mind but lack control of their strength and power. On top of their enhanced physical strength, they also have improved speed and regenerative abilities.', traits: ['weapons', 'undead'], image: require('../assets/rare.png')},
                    {name: 'mythic', percentage: 17.5, description: 'During the lightning strike, a small fraction of the parasites become ionically charged and when attached to a host turns them into Mythical beings. Mythic Lamps are the most powerful of the three. They have complete control over the parasites and the human mind. Their physical strength and senses are unrivaled and enchanted to its peak. With great power comes great opportunities, and every mythic will be different from the other.', traits: ['spiritual', 'mystical'], image: require('../assets/mythic.png')},
                    {name: 'common', percentage: 48.7, description: 'The weakest of the three. Common Lamps are infected by parasites. They lost control of their human mind but in return, developed super strength. They instinctively infect other humans by shooting parasites through their hands and consume them.', traits: ['food', 'nature', 'profession'], image: require('../assets/common.png')},
                ]
        }
    },
}
</script>

<style scoped>
.rarity{
    position: relative;
    width: 100%;
    height: fit-content;
    background: black;
}
.triangle-1, .triangle-2{
    position: absolute;
    bottom: 0;
    width: 100vw;
    z-index: 98;
}
.triangle-1{
    left: 0;
}
.triangle-2{
    right: 0;
}
.content{
    position: relative;
    z-index: 99;
}
.is-size-2{
    font-family: 'Rubik', sans-serif;
}
.column{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
}
.image-container{
    background: linear-gradient(180deg, #6667BD 9.13%, rgba(102, 103, 189, 0) 81.87%);
    border-radius: 24px;
    padding: 32px;
}
img{
    width: 220px;
    border-radius: 24px;
}
.description{
    color: #A5A6FB;
    text-align: justify;
}
.traits-title, .traits-container{
    align-self: flex-start;
}
.traits-container{
    display: flex;
}
.trait{
    background: #C3C4F4;
    color: #331B7C;
    padding: 8px 12px;
    border-radius: 8px;
    margin-right: 12px;
}
.percentage-container{
    justify-content: center;
    align-items: center;
}
.percentage-container svg{
    margin-right: 12px;
}
.container {
    max-width: 80% !important;
}
/* tablet */
@media screen and (max-width: 1023px) {
    .image-container{
        border-radius: 12px;
        padding: 32px;
    }
    img{
        width: 160px;
        border-radius: 12px;
    }
    .columns{
        display: flex;
        flex-direction: column;
    }
    .column:nth-child(3){
        order: 1;
    }
    .column:nth-child(2){
        order: 3;
    }
    .column:nth-child(1){
        order: 2;
    }
    .triangle-1, .triangle-2{
        position: absolute;
        bottom: 0;
        width: 100vw;
        z-index: 98;
    }
    .triangle-1{
        left: 0;
    }
    .triangle-2{
        right: 0;
    }
}
/* mobile */
@media screen and (max-width: 768px){
    .image-container{
        border-radius: 12px;
        padding: 32px;
    }
    img{
        width: 200px;
        border-radius: 12px;
    }
}
</style>