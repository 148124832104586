<template>
    <footer class="footer section">
        <Logo class="logo" />
        <div class="menu">
            <router-link :to="'/webcomic'" class="navbar-item is-uppercase link" @click="() => {
                $store.state.showNav = false
                if(!holder){
                    $store.state.showNotification = true
                }
            }">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M9 4.804A7.968 7.968 0 005.5 4c-1.255 0-2.443.29-3.5.804v10A7.969 7.969 0 015.5 14c1.669 0 3.218.51 4.5 1.385A7.962 7.962 0 0114.5 14c1.255 0 2.443.29 3.5.804v-10A7.968 7.968 0 0014.5 4c-1.255 0-2.443.29-3.5.804V12a1 1 0 11-2 0V4.804z" />
                </svg>
                <p>Webcomic</p>
            </router-link>
            <a href="/#about" class="navbar-item is-uppercase link" @click="$store.state.showNav = false">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" >
                    <path d="M10 3.5a1.5 1.5 0 013 0V4a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-.5a1.5 1.5 0 000 3h.5a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-.5a1.5 1.5 0 00-3 0v.5a1 1 0 01-1 1H6a1 1 0 01-1-1v-3a1 1 0 00-1-1h-.5a1.5 1.5 0 010-3H4a1 1 0 001-1V6a1 1 0 011-1h3a1 1 0 001-1v-.5z" />
                </svg>
                <p>about</p>
            </a>
            <a href="/#rarity" class="navbar-item is-uppercase link" @click="$store.state.showNav = false" >
                <svg xmlns="http://www.w3.org/2000/svg" class="icon" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M5 2a1 1 0 011 1v1h1a1 1 0 010 2H6v1a1 1 0 01-2 0V6H3a1 1 0 010-2h1V3a1 1 0 011-1zm0 10a1 1 0 011 1v1h1a1 1 0 110 2H6v1a1 1 0 11-2 0v-1H3a1 1 0 110-2h1v-1a1 1 0 011-1zM12 2a1 1 0 01.967.744L14.146 7.2 17.5 9.134a1 1 0 010 1.732l-3.354 1.935-1.18 4.455a1 1 0 01-1.933 0L9.854 12.8 6.5 10.866a1 1 0 010-1.732l3.354-1.935 1.18-4.455A1 1 0 0112 2z" clipRule="evenodd" />
                </svg>
                <p>rarity</p>
            </a>
            <a href="/#roadmap" class="navbar-item is-uppercase link" @click="$store.state.showNav = false">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M12 1.586l-4 4v12.828l4-4V1.586zM3.707 3.293A1 1 0 002 4v10a1 1 0 00.293.707L6 18.414V5.586L3.707 3.293zM17.707 5.293L14 1.586v12.828l2.293 2.293A1 1 0 0018 16V6a1 1 0 00-.293-.707z" clipRule="evenodd" />
                </svg>
                <p>roadmap</p>
            </a>
            <a href="/#team" class="navbar-item is-uppercase link" @click="$store.state.showNav = false" >
                <svg xmlns="http://www.w3.org/2000/svg" class="icon" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
                </svg>
                <p>team</p>
            </a>
            <a href="https://twitter.com/persona_lamps" class="navbar-item is-uppercase link" @click="$store.state.showNav = false" >
                <svg  class="icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20 3.7975C19.2642 4.12417 18.4733 4.34417 17.6433 4.44334C18.4908 3.93584 19.1417 3.13167 19.4475 2.17334C18.655 2.64334 17.7767 2.985 16.8417 3.16917C16.0942 2.37167 15.0267 1.87334 13.8467 1.87334C11.1975 1.87334 9.25083 4.345 9.84917 6.91084C6.44 6.74 3.41667 5.10667 1.3925 2.62417C0.3175 4.46834 0.835 6.88084 2.66167 8.1025C1.99 8.08084 1.35667 7.89667 0.804167 7.58917C0.759167 9.49 2.12167 11.2683 4.095 11.6642C3.5175 11.8208 2.885 11.8575 2.24167 11.7342C2.76333 13.3642 4.27833 14.55 6.075 14.5833C4.35 15.9358 2.17667 16.54 0 16.2833C1.81583 17.4475 3.97333 18.1267 6.29 18.1267C13.9083 18.1267 18.2125 11.6925 17.9525 5.92167C18.7542 5.3425 19.45 4.62 20 3.7975V3.7975Z"/>
                </svg>
                <p>twitter</p>
            </a>
            <a href="https://discord.com/invite/personalamps" class="navbar-item is-uppercase link" @click="$store.state.showNav = false" >
                <svg  class="icon" width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.9308 1.26368C15.6561 0.678776 14.2892 0.247847 12.8599 0.00103534C12.8339 -0.00372804 12.8079 0.00817591 12.7945 0.0319844C12.6187 0.344664 12.4239 0.752579 12.2876 1.0732C10.7503 0.843058 9.22099 0.843058 7.71527 1.0732C7.57887 0.745452 7.37707 0.344664 7.20048 0.0319844C7.18707 0.00897027 7.16107 -0.00293367 7.13504 0.00103534C5.70659 0.247058 4.33963 0.677987 3.06411 1.26368C3.05307 1.26844 3.04361 1.27638 3.03732 1.28669C0.444493 5.16033 -0.265792 8.93875 0.0826501 12.6703C0.0842267 12.6886 0.0944749 12.706 0.108665 12.7171C1.81934 13.9734 3.47642 14.7361 5.10273 15.2416C5.12876 15.2496 5.15634 15.24 5.1729 15.2186C5.55761 14.6933 5.90054 14.1393 6.19456 13.5568C6.21192 13.5227 6.19535 13.4822 6.15989 13.4687C5.61594 13.2624 5.098 13.0108 4.59978 12.7251C4.56037 12.7021 4.55721 12.6457 4.59347 12.6187C4.69831 12.5402 4.80318 12.4584 4.9033 12.3759C4.92141 12.3608 4.94665 12.3576 4.96794 12.3671C8.24107 13.8615 11.7846 13.8615 15.0191 12.3671C15.0404 12.3568 15.0657 12.36 15.0846 12.3751C15.1847 12.4576 15.2896 12.5402 15.3952 12.6187C15.4314 12.6457 15.4291 12.7021 15.3897 12.7251C14.8914 13.0163 14.3735 13.2624 13.8288 13.4679C13.7933 13.4814 13.7775 13.5227 13.7949 13.5568C14.0952 14.1385 14.4381 14.6924 14.8157 15.2178C14.8315 15.24 14.8599 15.2496 14.8859 15.2416C16.5201 14.7361 18.1772 13.9734 19.8879 12.7171C19.9028 12.706 19.9123 12.6894 19.9139 12.6711C20.3309 8.357 19.2154 4.60956 16.9568 1.28748C16.9513 1.27638 16.9419 1.26844 16.9308 1.26368ZM6.68335 10.3982C5.69792 10.3982 4.88594 9.49348 4.88594 8.38241C4.88594 7.27134 5.68217 6.36664 6.68335 6.36664C7.69239 6.36664 8.49651 7.27928 8.48073 8.38241C8.48073 9.49348 7.68451 10.3982 6.68335 10.3982ZM13.329 10.3982C12.3435 10.3982 11.5316 9.49348 11.5316 8.38241C11.5316 7.27134 12.3278 6.36664 13.329 6.36664C14.338 6.36664 15.1421 7.27928 15.1264 8.38241C15.1264 9.49348 14.338 10.3982 13.329 10.3982Z"/>
                </svg>
                <p>discord</p>
            </a>
            <a href="https://www.instagram.com/personalamps" class="navbar-item is-uppercase link" @click="$store.state.showNav = false" >
                <svg  class="icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_139_3482)">
                        <path d="M12 2.163C15.204 2.163 15.584 2.175 16.85 2.233C20.102 2.381 21.621 3.924 21.769 7.152C21.827 8.417 21.838 8.797 21.838 12.001C21.838 15.206 21.826 15.585 21.769 16.85C21.62 20.075 20.105 21.621 16.85 21.769C15.584 21.827 15.206 21.839 12 21.839C8.796 21.839 8.416 21.827 7.151 21.769C3.891 21.62 2.38 20.07 2.232 16.849C2.174 15.584 2.162 15.205 2.162 12C2.162 8.796 2.175 8.417 2.232 7.151C2.381 3.924 3.896 2.38 7.151 2.232C8.417 2.175 8.796 2.163 12 2.163V2.163ZM12 0C8.741 0 8.333 0.014 7.053 0.072C2.695 0.272 0.273 2.69 0.073 7.052C0.014 8.333 0 8.741 0 12C0 15.259 0.014 15.668 0.072 16.948C0.272 21.306 2.69 23.728 7.052 23.928C8.333 23.986 8.741 24 12 24C15.259 24 15.668 23.986 16.948 23.928C21.302 23.728 23.73 21.31 23.927 16.948C23.986 15.668 24 15.259 24 12C24 8.741 23.986 8.333 23.928 7.053C23.732 2.699 21.311 0.273 16.949 0.073C15.668 0.014 15.259 0 12 0V0ZM12 5.838C8.597 5.838 5.838 8.597 5.838 12C5.838 15.403 8.597 18.163 12 18.163C15.403 18.163 18.162 15.404 18.162 12C18.162 8.597 15.403 5.838 12 5.838ZM12 16C9.791 16 8 14.21 8 12C8 9.791 9.791 8 12 8C14.209 8 16 9.791 16 12C16 14.21 14.209 16 12 16ZM18.406 4.155C17.61 4.155 16.965 4.8 16.965 5.595C16.965 6.39 17.61 7.035 18.406 7.035C19.201 7.035 19.845 6.39 19.845 5.595C19.845 4.8 19.201 4.155 18.406 4.155Z"/>
                    </g>
                </svg>
                <p>instagram</p>
            </a>
        </div>
    </footer>
</template>

<script>
import Logo from '@/components/Logo.vue'
export default {
    components: {
        Logo
    }
}
</script>

<style scoped>
.footer{
    background: linear-gradient(180deg, #000000 0%, #1E201F 100%);
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.logo{
    max-width: 180px;
}
.menu{
    display: flex;
    align-items: center;
}
.link{
    color: white;
    transition: 300ms;
}
.link:hover{
    background: transparent;
    color: #5D5FEF;
}
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 100px;
  background-color: #5D5FEF;
  color: white;
  text-align: center;
  border-radius: 4px;
  padding: 4px 0;
  font-size: 10px;
  position: absolute;
  z-index: 1;
  transform: translateX(-90px) translateY(-40px);
}
.tooltip:hover .tooltiptext {
  visibility: visible;
}
.icon{
    width: 24px;
    margin-right: 8px;
}
.link:hover svg{
    fill: #5D5FEF;
}
svg{
    fill: white;
}
/* mobile */
@media screen and (max-width: 768px){
   .menu{
       flex-direction: column;
   }
   .navbar-item{
       display: flex;
       margin-bottom: 10px;
   }
   .tooltiptext{
       display: none;
   }
}
</style>